/*
  According to https://github.com/styled-components/styled-components/issues/2227
  font definitions should be still placed in .css file to avoid refetching fonts and
  unnecessary rerenders
*/

@font-face {
  font-family: Graphik;
  font-weight: 200;
  src: url('assets/fonts/Graphik-Extralight-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 400;
  src: url('assets/fonts/Graphik-Regular-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 500;
  src: url('assets/fonts/Graphik-Medium-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 600;
  src: url('assets/fonts/Graphik-Semibold-Web.woff2') format('woff2');
}

@font-face {
  font-family: SFMono;
  font-weight: 600;
  src: url('assets/fonts/SFMonoRegular.otf') format('opentype');
}
